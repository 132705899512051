<script setup>
import { ref, onMounted, onUnmounted, nextTick, watch } from "vue";
import { SmileIcon } from "lucide-vue-next";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

const $emit = defineEmits(["select"]);
const isOpen = ref(false);
const pickerRef = ref(null);
const buttonRef = ref(null);
const pickerStyle = ref({
    position: "fixed",
    bottom: "auto",
    top: "auto",
    left: "auto",
    right: "auto",
    zIndex: 9999,
});

const updatePickerPosition = () => {
    if (!buttonRef.value || !pickerRef.value) return;

    const buttonRect = buttonRef.value.getBoundingClientRect();
    const pickerWidth = 320; // Largeur définie du sélecteur
    const viewportWidth = window.innerWidth;

    let left = buttonRect.left;
    let top = buttonRect.bottom;

    // Ajustement si le sélecteur dépasse à droite
    if (left + pickerWidth > viewportWidth) {
        left = viewportWidth - pickerWidth - 10; // Marge de 10px
    }

    // Ajustement si le sélecteur dépasse en bas
    const pickerHeight = 350; // Hauteur maximale définie pour le sélecteur
    const viewportHeight = window.innerHeight;
    if (top + pickerHeight > viewportHeight) {
        top = buttonRect.top - pickerHeight;
    }

    pickerStyle.value = {
        position: "fixed",
        top: `${top}px`,
        left: `${left}px`,
        zIndex: 9999,
        width: "320px",
        maxHeight: "350px",
        overflow: "hidden",
    };
};

const toggleMenu = async () => {
    isOpen.value = !isOpen.value;
    if (isOpen.value) {
        await nextTick();
        updatePickerPosition();
    }
};

const onSelectEmoji = (emoji) => {
    $emit("select", emoji.i);
    isOpen.value = false;
};

const closeMenuOnOutsideClick = (event) => {
    if (
        isOpen.value &&
        buttonRef.value &&
        pickerRef.value &&
        !buttonRef.value.contains(event.target) &&
        !pickerRef.value.contains(event.target)
    ) {
        isOpen.value = false;
    }
};

onMounted(() => {
    document.addEventListener("click", closeMenuOnOutsideClick);
    window.addEventListener("resize", updatePickerPosition);
});

onUnmounted(() => {
    document.removeEventListener("click", closeMenuOnOutsideClick);
    window.removeEventListener("resize", updatePickerPosition);
});
</script>

<template>
    <div class="relative">
        <!-- Bouton pour ouvrir le sélecteur -->
        <button
            ref="buttonRef"
            type="button"
            class="lg:p-3.5 p-2 lg:rounded-md rounded-sm bg-light aspect-square"
            @click="toggleMenu"
        >
            <SmileIcon class="w-5 h-5" />
        </button>

        <!-- Sélecteur d'emojis -->
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
        >
            <div
                v-show="isOpen"
                ref="pickerRef"
                :style="pickerStyle"
                class="emoji-picker-container overflow-hidden"
            >
                <EmojiPicker
                    hide-group-icons
                    native="true"
                    @select="onSelectEmoji"
                />
            </div>
        </transition>
    </div>
</template>
