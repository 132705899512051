import { ref } from 'vue'

const isOpen = ref(false)
const items = ref([])
const currentIndex = ref(0)
const highlightedCommentUuid = ref(null)
const autoLoadComments = ref(false)

export function useMediaViewer() {
    const open = (mediaItems, startIndex = 0, options = {}) => {
        items.value = Array.isArray(mediaItems) ? mediaItems : [mediaItems]
        currentIndex.value = startIndex
        isOpen.value = true

        if (options.highlightedCommentUuid) {
            highlightedCommentUuid.value = options.highlightedCommentUuid
            autoLoadComments.value = true
        }
    }

    const close = () => {
        isOpen.value = false
    }

    const reset = () => {
        items.value = []
        currentIndex.value = 0
        isOpen.value = false
        highlightedCommentUuid.value = null
        autoLoadComments.value = false
    }

    const next = () => {
        if (items.value.length <= 1) return
        currentIndex.value = (currentIndex.value + 1) % items.value.length
    }

    const prev = () => {
        if (items.value.length <= 1) return
        currentIndex.value = (currentIndex.value - 1 + items.value.length) % items.value.length
    }

    return {
        isOpen,
        items,
        currentIndex,
        highlightedCommentUuid,
        autoLoadComments,
        open,
        close,
        next,
        prev,
        reset
    }
}