import { useMediaViewer } from '@/Composables/media-viewer';


export const createMediaViewerPlugin = (app) => {
    if (typeof window === 'undefined') return

    // console.log('Initializing Media Viewer Plugin') // Debug log

    const { open } = useMediaViewer()
    let observer = null

    const findGalleryItems = (element) => {
        const gallery = element.dataset.gallery
        if (!gallery) return [element]

        const galleryItems = [...document.querySelectorAll(`[data-gallery="${gallery}"]`)]
        // console.log('Found gallery items:', galleryItems) // Debug log
        return galleryItems
    }

    const createMediaItem = (element) => {
        const isVideo = element.tagName.toLowerCase() === 'video'

        const item = {
            type: isVideo ? 'video' : 'image',
            url: isVideo ? (element.currentSrc || element.src) : element.src,
            thumb: isVideo ? (element.poster || element.src) : element.src,
            name: element.alt || element.title || '',
            element
        }

        // console.log('Created media item:', item) // Debug log
        return item
    }

    const setupMediaElement = (element) => {
        // Skip if already initialized or not a media element
        if (element.dataset.viewerInitialized) return
        if (!['IMG', 'VIDEO'].includes(element.tagName)) return
        if (element.closest('button, a, [role="button"]')) return

        // Add necessary attributes
        element.style.cursor = 'pointer'
        element.dataset.viewerInitialized = 'true'

        const clickHandler = (e) => {
            // console.log('Media element clicked:', element) // Debug log
            e.preventDefault()
            e.stopPropagation()

            const galleryItems = findGalleryItems(element)
                .map(createMediaItem)
                .filter(Boolean)

            const currentIndex = galleryItems.findIndex(
                item => item.element === element
            )

            // console.log('Opening viewer with gallery items:', galleryItems, currentIndex) // Debug log
            open(galleryItems, currentIndex)
        }

        // Remove existing listener if any
        element.removeEventListener('click', clickHandler)
        // Add new click listener
        element.addEventListener('click', clickHandler)
    }

    const initObserver = () => {
        // console.log('Initializing observer') // Debug log

        if (observer) {
            observer.disconnect()
        }

        observer = new MutationObserver((mutations) => {
            mutations.forEach(mutation => {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        if (['IMG', 'VIDEO'].includes(node.tagName)) {
                            setupMediaElement(node)
                        }
                        node.querySelectorAll('img, video').forEach(setupMediaElement)
                    }
                })
            })
        })

        observer.observe(document.body, {
            childList: true,
            subtree: true
        })

        // Setup existing elements
        document.querySelectorAll('img, video').forEach(setupMediaElement)
    }

    // Handle Inertia page transitions
    if (document.addEventListener) {
        document.addEventListener('inertia:navigate', () => {
            if (observer) {
                observer.disconnect()
            }
        })

        document.addEventListener('inertia:finish', () => {
            setTimeout(initObserver, 0)
        })
    }

    // Initial setup
    initObserver()
}