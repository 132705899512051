<script setup>
import {
    computed,
    ref,
    onMounted,
    onBeforeUnmount,
    watch,
    onUnmounted,
} from "vue";
import {
    Dialog,
    DialogPanel,
    TransitionRoot,
    TransitionChild,
} from "@headlessui/vue";
import { ChevronLeft, ChevronRight, X } from "lucide-vue-next";
import { useMediaViewer } from "@/Composables/media-viewer";
import CommentSection from "@/Pages/Posts/Partials/PostCardPartials/CommentSection.vue";
import LikeButton from "@/Pages/Posts/Partials/PostCardPartials/LikeButton.vue";
import CommentButton from "@/Pages/Posts/Partials/PostCardPartials/CommentButton.vue";

const { isOpen, items, currentIndex, close, next, prev, reset } =
    useMediaViewer();

const videoRef = ref(null);
const currentItem = computed(() => items.value[currentIndex.value]);

// État des commentaires
const comments = ref(new Map());
const hasLoadedComments = ref(new Map());

// Chargement des commentaires pour le média actuel
const loadCommentsForCurrentItem = async (item) => {
    if (!item?.id || hasLoadedComments.value.get(item.id)) {
        return;
    }

    try {
        const response = await axios.get(
            route("media.comments.index", item.id)
        );
        comments.value.set(item.id, response.data.comments);
        hasLoadedComments.value.set(item.id, true);
    } catch (error) {
        console.error("Error loading comments:", error);
    }
};

// Gérer la mise à jour des commentaires
const handleCommentsUpdated = (count) => {
    if (currentItem.value) {
        currentItem.value.comments_count = count;
    }
};

// Gérer la mise à jour des likes
const handleLikeUpdated = (data) => {
    if (currentItem.value) {
        currentItem.value.is_liked = data.isLiked;
        currentItem.value.likes_count = data.likesCount;
    }
};

const handleKeydown = (e) => {
    if (!isOpen.value) return;

    switch (e.key) {
        case "ArrowRight":
            next();
            break;
        case "ArrowLeft":
            prev();
            break;
        case "Escape":
            handleClose();
            break;
    }
};

const handleVideoEnd = () => {
    if (items.value.length > 1) {
        next();
    }
};

const loadCurrentItemData = async (item) => {
    if (!item?.id) return;

    try {
        const response = await axios.get(route("media.show", item.id));
        const media = response.data?.media;

        if (media) {
            // Met à jour les compteurs et le statut du like dans l'item courant
            items.value[currentIndex.value] = {
                ...items.value[currentIndex.value],
                likes_count: media.likes_count,
                comments_count: media.comments_count,
                is_liked: media.is_liked,
            };
        }
    } catch (error) {
        console.error("Error loading media data:", error);
    }
};

watch(
    [isOpen, items, currentIndex],
    async ([newIsOpen, newItems, newIndex], [oldIsOpen, _, oldIndex]) => {
        if (newIsOpen && (!oldIsOpen || newIndex !== oldIndex)) {
            const item = newItems[newIndex];
            if (item) {
                // Charge les commentaires
                await loadCommentsForCurrentItem(item);
                // Charge les données mises à jour du média
                await loadCurrentItemData(item);
            }
        }

        if (videoRef.value) {
            videoRef.value?.pause();
        }
    },
    { immediate: true }
);

watch(isOpen, (newValue, oldValue) => {
    if (oldValue && !newValue) {
        comments.value.clear();
        hasLoadedComments.value.clear();
        reset();
    }
});

const handleClose = () => {
    comments.value.clear();
    hasLoadedComments.value.clear();
    reset();
    close();
};

onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
});

onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleKeydown);
});

onUnmounted(() => {
    document.removeEventListener("keydown", handleKeydown);
    handleClose();
});
</script>

<template>
    <Teleport to="body">
        <TransitionRoot appear :show="isOpen" as="template">
            <Dialog as="div" @close="close" class="relative z-[100000]">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-black/95 backdrop-blur-sm" />
                </TransitionChild>

                <div class="fixed inset-0 overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center">
                        <TransitionChild
                            as="template"
                            enter="duration-300 ease-out"
                            enter-from="opacity-0 scale-95"
                            enter-to="opacity-100 scale-100"
                            leave="duration-200 ease-in"
                            leave-from="opacity-100 scale-100"
                            leave-to="opacity-0 scale-95"
                        >
                            <DialogPanel
                                class="w-screen lg:h-screen flex lg:flex-row flex-col overflow-y-scroll"
                            >
                                <!-- Main content -->
                                <div class="flex-1 relative min-h-screen">
                                    <!-- Navigation -->
                                    <button
                                        v-if="items.length > 1"
                                        @click="prev"
                                        class="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-primary text-white hover:bg-primary/900 transition-colors z-[2000]"
                                    >
                                        <ChevronLeft class="w-6 h-6" />
                                    </button>

                                    <button
                                        v-if="items.length > 1"
                                        @click="next"
                                        class="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-primary text-white hover:bg-primary/900 transition-colors z-[2000]"
                                    >
                                        <ChevronRight class="w-6 h-6" />
                                    </button>

                                    <!-- Close button -->
                                    <button
                                        @click="close"
                                        class="absolute top-4 right-4 p-2 rounded-full bg-gray-800 text-white hover:bg-primary/900 transition-colors z-[2000]"
                                    >
                                        <X class="w-6 h-6" />
                                    </button>

                                    <!-- Media display -->
                                    <div class="relative max-h-full max-w-full">
                                        <TransitionGroup
                                            name="media"
                                            tag="div"
                                            class="relative h-screen"
                                        >
                                            <div
                                                :key="currentItem?.url"
                                                class="absolute bottom-0 top-0 left-0 right-0 flex items-center justify-center"
                                            >
                                                <img
                                                    :src="currentItem?.url"
                                                    class="max-h-[85vh] max-w-full object-contain"
                                                    :alt="currentItem?.name"
                                                    v-if="
                                                        currentItem?.mime_type?.includes(
                                                            'image'
                                                        )
                                                    "
                                                />
                                                <div
                                                    v-else-if="
                                                        currentItem?.mime_type?.includes(
                                                            'video'
                                                        )
                                                    "
                                                    class="aspect-video"
                                                >
                                                    <video-player
                                                        :src="currentItem?.url"
                                                        controls
                                                        autoplay
                                                        class="max-h-[65vh] lg:max-w-[40vw] max-w-[70vw]"
                                                        @ended="handleVideoEnd"
                                                    />
                                                </div>
                                            </div>
                                        </TransitionGroup>
                                    </div>

                                    <!-- Thumbnails -->
                                    <div
                                        v-if="items.length > 1"
                                        class="absolute bottom-4 left-1/2 -translate-x-1/2 z-[1000]"
                                    >
                                        <div
                                            class="flex gap-2 p-2 rounded-lg bg-black/50 backdrop-blur-sm"
                                        >
                                            <button
                                                v-for="(item, index) in items"
                                                :key="item.url"
                                                @click="currentIndex = index"
                                                class="w-16 h-16 rounded-md overflow-hidden transition-all duration-200 outline outline-2 outline-offset-2"
                                                :class="
                                                    currentIndex === index
                                                        ? 'outline-primary'
                                                        : 'outline-transparent opacity-50 hover:opacity-80'
                                                "
                                            >
                                                <img
                                                    v-if="item.type === 'image'"
                                                    :src="item.url"
                                                    class="w-full h-full object-cover"
                                                    :alt="item.name"
                                                />
                                                <img
                                                    v-else
                                                    :src="item.thumb"
                                                    class="w-full h-full object-cover"
                                                    :alt="item.name"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Sidebar with comments -->
                                <div
                                    class="lg:w-[32rem] w-full bg-white h-screen flex flex-col z-[20000]"
                                >
                                    <!-- En-tête fixe -->
                                    <div class="flex-none border-b">
                                        <div class="p-4">
                                            <h3 class="text-lg font-semibold">
                                                Commentaires
                                            </h3>
                                        </div>

                                        <!-- Stats bar -->
                                        <div
                                            class="px-4 py-3 flex items-center gap-4 border-b"
                                        >
                                            <LikeButton
                                                v-if="currentItem"
                                                :post="currentItem"
                                                :is-liked="currentItem.is_liked"
                                                :likes-count="
                                                    currentItem.likes_count
                                                "
                                                type="media"
                                                @like-updated="
                                                    handleLikeUpdated
                                                "
                                            />
                                            <CommentButton
                                                v-if="currentItem"
                                                :comments-count="
                                                    currentItem.comments_count
                                                "
                                            />
                                        </div>
                                    </div>

                                    <!-- Zone de défilement pour les commentaires -->
                                    <div class="flex-1 overflow-y-auto">
                                        <div
                                            v-if="
                                                !hasLoadedComments.get(
                                                    currentItem?.id
                                                )
                                            "
                                            class="p-4 text-center"
                                        >
                                            <div
                                                class="animate-spin h-5 w-5 border-2 border-primary border-t-transparent rounded-full mx-auto"
                                            ></div>
                                            <p
                                                class="text-sm text-gray-500 mt-2"
                                            >
                                                Chargement des commentaires...
                                            </p>
                                        </div>

                                        <CommentSection
                                            v-else-if="currentItem"
                                            :key="currentItem.id"
                                            :post="currentItem"
                                            commentable-type="media"
                                            :commentable-id="currentItem.id"
                                            :initial-comments="
                                                comments.get(currentItem.id) ||
                                                []
                                            "
                                            @comments-updated="
                                                handleCommentsUpdated
                                            "
                                            class="h-full"
                                        />
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </Teleport>
</template>

<style scoped>
.media-move,
.media-enter-active,
.media-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
}

.media-enter-from,
.media-leave-to {
    opacity: 0;
}

.media-enter-to,
.media-leave-from {
    opacity: 1;
}

.media-leave-active {
    z-index: 1;
}

.media-enter-active {
    z-index: 0;
}
</style>
