<script setup>
import { HeartIcon } from "lucide-vue-next";
import axios from "axios";

const props = defineProps({
    post: Object,
    isLiked: Boolean,
    likesCount: Number,
    type: {
        type: String,
        default: "post",
        validator: (value) => ["post", "media"].includes(value),
    },
});

const emit = defineEmits(["likeUpdated", "open-likes-modal"]);

const toggleLike = () => {
    const endpoint =
        props.type === "post"
            ? route("posts.like", props.post.uuid)
            : route("media.like", props.post.id);

    axios.post(endpoint).then((response) => {
        emit("likeUpdated", {
            isLiked: response.data.is_liked,
            likesCount: response.data.likes_count,
        });
    });
};
</script>

<template>
    <button
        @click="toggleLike"
        class="flex items-center justify-center gap-2"
        premium
    >
        <span class="text-primary relative bottom-0.5">
            <HeartIcon
                class="w-6 h-6"
                :fill="isLiked ? 'currentColor' : 'transparent'"
            />
        </span>
    </button>
    <button @click="$emit('open-likes-modal')" class="text-primary" premium>
        {{ likesCount }} J'aime
    </button>
</template>
