import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";
import FloatingVue from "floating-vue";

import VueVideoPlayer from "@videojs-player/vue";
import "video.js/dist/video-js.css";

import "driver.js/dist/driver.css";

import "floating-vue/dist/style.css";

import "./moment";

import "vue3-toastify/dist/index.css";

import { register } from "swiper/element/bundle";
import * as Sentry from "@sentry/vue";

register();

import "@vuepic/vue-datepicker/dist/main.css";
import AgoraRTC from "agora-rtc-sdk-ng";

import { createMediaViewerPlugin } from "@/Plugins/MediaViewerPlugin";
import MediaViewer from "@/Components/MediaViewer/MediaViewer.vue";

AgoraRTC.setLogLevel(4);

const appName = import.meta.env.VITE_APP_NAME || "Laravel";
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/(www\.)?plaisironline\.fr/,
            ],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        app.use(plugin);
        app.use(FloatingVue);
        app.use(ZiggyVue);
        // app.use(Vue3VideoPlayer);
        app.use(VueVideoPlayer);
        app.component("MediaViewer", MediaViewer);
        app.use(createMediaViewerPlugin);

        app.mount(el);
    },
    progress: {
        color: "#1c1e21",
    },
});
