<script setup>
import { MessageCircleIcon } from "lucide-vue-next";

defineProps({
    commentsCount: Number,
});

const emit = defineEmits(["toggleComments"]);

const toggleComments = () => {
    emit("toggleComments");
};
</script>

<template>
    <button
        @click="toggleComments"
        class="flex items-center justify-center gap-2"
    >
        <span class="text-primary relative bottom-0.5">
            <MessageCircleIcon class="w-6 h-6" />
        </span>
        <span>{{ commentsCount }} Commentaires</span>
    </button>
</template>
