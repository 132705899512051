<script setup>
import { ref } from "vue";
import DialogModal from "@/Components/DialogModal.vue";
import Button from "@/Components/Button.vue";

const props = defineProps({
    show: Boolean,
    title: {
        type: String,
        default: "Confirmation",
    },
    content: {
        type: String,
        required: true,
    },
    confirmText: {
        type: String,
        default: "Confirmer",
    },
    cancelText: {
        type: String,
        default: "Annuler",
    },
    processing: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["close", "confirm"]);

const close = () => {
    emit("close");
};

const confirm = () => {
    emit("confirm");
};
</script>

<template>
    <DialogModal :show="show" @close="close" class="border">
        <template #title>
            {{ title }}
        </template>
        <template #content>
            {{ content }}
        </template>
        <template #footer>
            <div>
                <Button @click="close" variant="outline" class="mr-2">
                    {{ cancelText }}
                </Button>
                <Button
                    @click="confirm"
                    variant="danger"
                    :disabled="processing"
                >
                    {{ processing ? `${confirmText}...` : confirmText }}
                </Button>
            </div>
        </template>
    </DialogModal>
</template>
